document.addEventListener("turbolinks:load", () => {
  $(".saved_address_filter").change(function() {
    let prefix = $(this).data("prefix");
    $(`#${prefix}name`).val($(this).find(':selected').data('name'));
    $(`#${prefix}city`).val($(this).find(':selected').data('city'));
    $(`#${prefix}state`).val($(this).find(':selected').data('state'));
    $(`#${prefix}zip`).val($(this).find(':selected').data('zip'));
    $(`#${prefix}street`).val($(this).find(':selected').data('street'));
    $(`#${prefix}country`).val($(this).find(':selected').data('country'));
    $(`#${prefix}phone`).val($(this).find(':selected').data('phone'));
    $(`#${prefix}apt_number`).val($(this).find(':selected').data('apt_number'));
  });
})
