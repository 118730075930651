document.addEventListener("turbolinks:load", () => {

    // $('.add_to_cart').on('click', function (){
    //     $.ajax({
    //         url: '/cart_items',
    //         type: "post",
    //         data: {
    //             item: {
    //                 item_id: $(this).data('item-id'),
    //                 quantity: $(`#cart_quantity_input_${$(this).data('item-id')}`).val()
    //             }
    //         },
    //         success: function(response) {
    //
    //         },
    //         error: function(xhr) {
    //         }
    //     });
    //
    // })
})
